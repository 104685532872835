<template>
  <Modal :title="title" :strapi-image-object="strapiImageObject" @close="$emit('close')">
    <div class="md:grid grid-cols-7 h-full items-center">
      <div class="col-span-5 col-start-2">
        <div v-if="successShow">
          <AtomTitle tag="h3">Сообщение отправлено!</AtomTitle>
          <p class="mt-10">В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.</p>
        </div>
        <form v-else class="flex flex-col gap-y-8 md:gap-y-10" @submit.prevent="send">
          <div>
            <MoleculeInputText
              v-model="payload.first_name"
              :error="submitted && error.first_name"
              label="Имя*"
              type="text"
              class="item-input mb-2"
              @blur="validate('first_name')"
              @input="validate('first_name')"
            />
            <MoleculeInputText
              v-model="payload.email"
              :error="submitted && error.email"
              label="Email*"
              type="email"
              class="item-input mb-2"
              @blur="validate('email')"
              @input="validate('email')"
            />
            <MoleculeInputPhone
              :error="submitted && error.phone"
              :value="payload.phone"
              class="mb-2"
              label="Телефон*"
              type="tel"
              theme="white"
              @onInput="onInputPhone"
              @blur="onInputPhone"
            />
            <MoleculeSelect
              v-model="payload.club"
              class="item-input mb-2"
              :error="submitted && error.club"
              :items="clubs"
              :initial-index="initialClub"
              placeholder="Выбрать клуб"
              @input="validate('club')"
            />
          </div>
          <MoleculeRadio
            v-model="radio"
            type="radio"
            :checked="radio"
            label="Я соглашаюсь с условиями обработки персональных данных"
            :error="submitted && error.radio"
            class="mb-8"
            @input="error.radio = !radio"
          />
          <AtomButton :disabled="submitting" class="md:self-baseline" tag="button" type="submit">ОТПРАВИТЬ</AtomButton>
          <div v-if="errorShow" class="text-red text-center text-sm mt-5">Произошла ошибка. Попробуйте позже</div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import MoleculeInputText from '../../molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '../../molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '../../molecules/forms/MoleculeRadio'
import MoleculeSelect from '../../molecules/forms/MoleculeSelect'
import Modal from '../../templates/layouts/Modal'
import { getUtmList } from '@/utils/cookies'

export default {
  name: 'OrganismModalVisitClub',
  components: {
    Modal,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeRadio,
    MoleculeSelect,
    AtomButton,
  },
  props: ['strapiImageObject', 'clubSlug'],
  data() {
    return {
      title: 'Посетить клуб',
      radio: false,
      payload: {
        first_name: '',
        email: '',
        phone: '',
        club: null,
      },
      error: {
        first_name: null,
        email: null,
        phone: null,
        club: null,
        radio: null,
      },
      submitted: false,
      submitting: false,
      successShow: false,
      errorShow: false,
    }
  },
  computed: {
    clubs() {
      return this.$store.getters['modules/content/getClubsAsListForm']
    },
    initialClub() {
      const index = this.clubs.findIndex(({ slug }) => slug === this.clubSlug)
      return index === -1 ? 0 : index
    },
  },
  methods: {
    async send() {
      this.errorShow = false
      this.submitted = true
      if (!this.submitting && this.validateForm()) {
        this.submitting = true
        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        let visitorUid = 'unknown'
        try {
          visitorUid = window.AMOPIXEL.getVisitorUid()
        } catch (e) {
          // console.error('No visitor_uid')
        }

        try {

          const utmList = getUtmList(this.$cookies)

          const payload = {
            ...this.payload,
            phone,
            club: this.payload.club.slug,
            visitor_uid: visitorUid,
            ...utmList,
          }

          const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

          if (sessionId) {
            payload.sessionId = sessionId
          }

          let clientId = 0
          try {
            clientId = window?.yaCounter37069155?.getClientID?.() ?? this.$cookies?.get('_ym_uid') ?? 0
          } catch (error) {
            console.error('clientId:', error)
          }

          if (clientId) {
            payload.clientId = String(clientId)
          }

          let roistatVisit = 'nocookie'
          try {
            roistatVisit = this.$cookies?.get('roistat_visit') || 'nocookie'
          } catch (error) {
            console.error('roistat_visit:', error)
          }

          payload.roistat_visit = String(roistatVisit)

          await this.$axios.post('/api/forms/visit', {
            data: payload,
          })
          this.success()
          this.sendReachGoal('success-form-visit', { api: 'forms/visit' })
        } catch (e) {
          this.errorShow = true
        } finally {
          this.submitting = false
        }
      }
    },
    success() {
      this.successShow = true
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio

      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        first_name: textIsInvalid,
        email: textIsInvalid,
        phone: v => !v || v.length !== 16,
        club: v => !v,
      }
      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
  },
}
</script>

<style lang="postcss" scoped></style>
