<template>
  <div v-if="successShow">
    <AtomTitle class="text-gray-500" tag="h3">Сообщение отправлено!</AtomTitle>
    <p class="text-gray-500 mt-10">В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.</p>
  </div>
  <form v-else class="fade-form" @submit.prevent="send">
    <div class="mb-8 flex flex-col gap-y-3">
      <MoleculeInputText
        v-model="payload.first_name"
        :error="submitted && error.first_name"
        label="Имя*"
        type="text"
        theme="dark"
        @blur="validate('first_name')"
        @input="validate('first_name')"
      />
      <MoleculeInputText
        v-model="payload.last_name"
        :error="submitted && error.last_name"
        label="Фамилия*"
        type="text"
        theme="dark"
        @blur="validate('last_name')"
        @input="validate('last_name')"
      />
      <MoleculeInputText
        v-model="payload.email"
        :error="submitted && error.email"
        label="Email*"
        type="email"
        theme="dark"
        @blur="validate('email')"
        @input="validate('email')"
      />
      <MoleculeInputPhone
        :error="submitted && error.phone"
        :value="payload.phone"
        label="Телефон*"
        type="tel"
        theme="dark"
        @onInput="onInputPhone"
        @blur="onInputPhone"
      />
      <MoleculeSelect
        v-model="payload.club"
        :error="submitted && error.club"
        :items="clubs"
        placeholder="Выбрать клуб"
        theme="dark"
        @input="validate('club')"
      />
    </div>
    <MoleculeRadio
      v-model="radio"
      type="radio"
      :checked="radio"
      label="Я соглашаюсь с условиями обработки персональных данных"
      :error="submitted && error.radio"
      class="dark mb-8"
      @input="error.radio = !radio"
    />
    <AtomButton
      :disabled="submitting"
      tag="button"
      type="submit"
      class="btn-white btn-outline -md:w-full btn-size-fixed"
    >
      ОТПРАВИТЬ
    </AtomButton>
    <div v-if="errorShow" class="text-red text-center text-sm mt-5">Произошла ошибка. Попробуйте позже</div>
  </form>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/builder/AtomTitle'
import MoleculeInputText from '../../molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '../../molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '../../molecules/forms/MoleculeRadio'
import MoleculeSelect from '../../molecules/forms/MoleculeSelect'
import { getUtmList } from '@/utils/cookies'

export default {
  name: 'OrganismFooterForms',
  components: {
    AtomTitle,
    AtomButton,
    MoleculeSelect,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeRadio,
  },
  data() {
    return {
      radio: false,
      phone: '',
      payload: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        club: null,
      },
      error: {
        first_name: null,
        last_name: null,
        email: null,
        phone: true,
        club: null,
        radio: null,
      },
      submitted: false,
      submitting: false,
      successShow: false,
      errorShow: false,
    }
  },
  computed: {
    clubs() {
      return this.$store.getters['modules/content/getClubsAsListForm']
    },
  },
  methods: {
    async send() {
      this.errorShow = false
      this.submitted = true
      
      if (!this.submitting && this.validateForm()) {
        this.submitting = true

        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        let visitorUid = 'unknown'
        try {
          visitorUid = window.AMOPIXEL.getVisitorUid()
        } catch (e) {
          // console.error('No visitor_uid')
        }

        try {
          const utmList = getUtmList(this.$cookies)

          const payload = {
            ...this.payload,
            phone,
            club: this.payload.club.slug,
            visitor_uid: visitorUid,
            ...utmList,
          }

          const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

          if (sessionId) {
            payload.sessionId = sessionId
          }

          let clientId = 0
          try {
            clientId = window?.yaCounter37069155?.getClientID?.() ?? this.$cookies?.get('_ym_uid') ?? 0
          } catch (error) {
            console.error('clientId:', error)
          }

          if (clientId) {
            payload.clientId = String(clientId)
          }

          let roistatVisit = 'nocookie'
          try {
            roistatVisit = this.$cookies?.get('roistat_visit') || 'nocookie'
          } catch (error) {
            console.error('roistat_visit:', error)
          }

          payload.roistat_visit = String(roistatVisit)

          await this.$axios.post('/api/forms/visit', {
            data: payload,
          })
          
          this.success()
          this.sendReachGoal('success-form-visit', { api: 'forms/visit' })
        } catch (e) {
          console.log('form', e)
          this.errorShow = true
        } finally {
          this.submitting = false
        }
      }
    },
    success() {
      this.successShow = true
      this.$emit('success')
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio

      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        first_name: textIsInvalid,
        last_name: textIsInvalid,
        email: textIsInvalid,
        phone: v => !v || v.length !== 16,
        club: v => !v,
      }

      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
  },
}
</script>
