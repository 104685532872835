<template>
  <Modal title="Заявка на вакансию" :strapi-image-object="vacancy.image || defaultImage" @close="$emit('close')">
    <div class="md:grid grid-cols-7 h-full items-center">
      <div class="col-span-5 col-start-2">
        <div v-if="successShow">
          <AtomTitle tag="h2" class="mb-10 md:mb-16"> Сообщение <br />отправлено!</AtomTitle>
          <AtomTitle tag="h3" class="max-w-[500px]">
            В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.
          </AtomTitle>
        </div>
        <form v-else class="white flex flex-col gap-y-8 md:gap-y-10 relative pb-5 md:pb-10" @submit.prevent="send">
          <div>
            <MoleculeSelect
              v-model="payload.club"
              :error="submitted && error.club"
              :items="clubsTransform"
              :entry-club="checkOnceVacancy()"
              :disabled="Boolean(clubsTransform.length === 1)"
              placeholder="Желаемый клуб"
              @input="validate('club')"
            />
            <MoleculeInputText
              v-model="payload.name"
              :error="submitted && error.name"
              label="Имя"
              class="item-input mb-2"
              type="text"
              @blur="validate('name')"
              @input="validate('name')"
            />
            <MoleculeInputPhone
              :error="submitted && error.phone"
              :value="payload.phone"
              label="Телефон*"
              type="tel"
              theme="white"
              @onInput="onInputPhone"
              @blur="onInputPhone"
            />
            <MoleculeInputText
              v-model="payload.email"
              :error="submitted && error.email"
              label="Email"
              class="item-input mb-2"
              @blur="validate('email')"
              @input="validate('email')"
            />
            <MoleculeTextarea v-model="payload.message" label="Сообщение" class="item-input mb-2" />
            <MoleculeInputText v-model="payload.linkResume" label="Ссылка на резюме" class="item-input mb-2" />
          </div>
          <AtomInputFile @change="payload.linkFile = $event" />
          <MoleculeRadio
            v-model="radio"
            :error="submitted && error.radio"
            type="radio"
            :checked="radio"
            label="Я соглашаюсь с условиями обработки <br /> персональных данных"
            @input="error.radio = !radio"
          />
          <AtomButton :disabled="submitting" class="md:self-baseline" tag="button" type="submit">ОТПРАВИТЬ</AtomButton>
          <div v-if="errorShow" class="text-red text-center text-sm mt-2 absolute bottom-[-20px]">
            Произошла ошибка. Попробуйте позже
          </div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/AtomTitle'
import AtomInputFile from '../../atoms/forms/AtomInputFile'
import MoleculeInputText from '../../molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '../../molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '../../molecules/forms/MoleculeRadio'
import MoleculeSelect from '../../molecules/forms/MoleculeSelect'
import MoleculeTextarea from '../../molecules/forms/MoleculeTextarea'
import Modal from '../../templates/layouts/Modal'
import { getUtmList } from '@/utils/cookies'

export default {
  name: 'OrganismModalVacancy',
  components: {
    AtomButton,
    AtomTitle,
    AtomInputFile,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeRadio,
    MoleculeSelect,
    MoleculeTextarea,
    Modal,
  },
  props: ['strapiImageObject', 'vacancy'],
  data() {
    return {
      submitted: false,
      submitting: false,
      successShow: false,
      errorShow: false,
      radio: false,
      payload: {
        message: '',
        club: null,
        name: '',
        phone: '',
        email: '',
        linkResume: '',
        linkFile: null,
        formId: null,
      },
      error: {
        club: null,
        name: null,
        phone: null,
        email: null,
      },
    }
  },
  computed: {
    clubs() {
      return this.ensureArray(this.vacancy.clubs)
    },
    clubsFromStore() {
      return this.$store.getters['modules/content/getClubsAsListForm']
    },
    vacancyForClub() {
      return this.clubs.map(club => ({
        id: club.id,
        text: club.title,
        slug: club.slug,
      }))
    },
    clubsFromStoreSorted() {
      return this.clubsFromStore.map(club => ({
        id: club.cityId,
        text: club.text,
        slug: club.slug,
      }))
    },
    clubsTransform() {
      return this.vacancyForClub.length ? this.vacancyForClub : this.clubsFromStoreSorted
    },
    defaultImage() {
      return this.$store.getters['modules/content/global'].vacancyImage
    },
  },
  methods: {
    send() {
      this.errorShow = false
      this.submitted = true
      if (!this.submitting && this.validateForm()) {
        this.submitting = true
        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        const formData = new FormData()

        const utmList = getUtmList(this.$cookies)

        const { linkFile, ...payload } = this.payload

        payload.club = this.payload.club.slug
        payload.phone = phone
        if (this.vacancy.id) {
          payload.formId = this.vacancy.id
        }
        if (this.vacancy.title) {
          payload.vacancy = this.vacancy.title
        }

        const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

        if (sessionId) {
          payload.sessionId = sessionId
        }

        let clientId = 0
        try {
          clientId = window?.yaCounter37069155?.getClientID?.() ?? this.$cookies?.get('_ym_uid') ?? 0
        } catch (error) {
          console.error('clientId:', error)
        }

        if (clientId) {
          payload.clientId = String(clientId)
        }

        let roistatVisit = 'nocookie'
        try {
          roistatVisit = this.$cookies?.get('roistat_visit') || 'nocookie'
        } catch (error) {
          console.error('roistat_visit:', error)
        }

        payload.roistat_visit = String(roistatVisit)

        if (linkFile) {
          formData.append('files.file', linkFile)
        }
        const formReq = {
          ...payload,
          ...utmList,
        }
        formData.append('data', JSON.stringify(formReq))

        this.$axios
          .post('/api/feedback-vacancy', formData)
          .then(() => {
            this.successShow = true
            this.sendReachGoal('success-form-vacancy', { api: 'feedback-vacancy' })
          })
          .catch(() => {
            this.errorShow = true
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio

      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        club: v => !v,
        name: textIsInvalid,
        phone: v => !v || v.length !== 16,
        email: textIsInvalid,
      }
      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
    checkOnceVacancy() {
      if (this.clubsTransform.length === 1) {
        return this.clubsTransform[0]
      } else {
        return null
      }
    },
  },
}
</script>
